import { useState, useMemo } from 'react'

type AsyncActionFn = (...args: any[]) => Promise<any>

export function useAsyncAction<T extends AsyncActionFn>(action: T, deps: any[] = []): [(...args: Parameters<T>) => Promise<any>, boolean] {
  const [loading, setLoading] = useState(false)

  const run = (...args: Parameters<T>) => {
    if (loading) {
      return
    }

    setLoading(true)

    return action(...args).finally(() => {
      setLoading(false)
    })
  }

  return useMemo(() => [run, loading], [run, loading, ...deps])
}
