import { useCallback, useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { AccountType, FastJsonObject } from '../type/Common'
import { useClient } from 'src/@legacy/@core/hooks/useClient'
import { useFetchAndStoreUser } from 'src/@legacy/@core/hooks/CurrentUserHooks'
import { API_URL_ACCOUNT } from '../utilities/APIConstant'
import { createSimpleEmitter } from '../utilities/Emitter'
export type FetchCurrentAccount = () => Promise<AxiosResponse<{ data: FastJsonObject<AccountType> }>>
export type UpdateCurrentAccount = (accout: AccountType) => Promise<AxiosResponse<FastJsonObject<AccountType>>>
const { emitAll, regiterCallback, unregisterCallBack } = createSimpleEmitter()
export const reloadUserData = emitAll
export const useAccountData: () => {
  fetchCurrentAccount: FetchCurrentAccount
  account_data: FastJsonObject<AccountType>
  updateCurrentAccount: UpdateCurrentAccount
  loadingData: boolean
} = () => {
  const { client } = useClient()
  const fetchAndStoreUser = useFetchAndStoreUser()
  const [account_data, set_account_data] = useState<FastJsonObject<AccountType>>()
  const [loadingData, setLoadingData] = useState<boolean>()
  const fetchCurrentAccount: FetchCurrentAccount = useCallback(() => {
    setLoadingData(true)
    return client
      .get(API_URL_ACCOUNT, {})
      .then((rs) => {
        set_account_data(rs.data.data)
        return rs
      })
      .finally(() => {
        setLoadingData(false)
      })
  }, [loadingData])
  const updateCurrentAccount: UpdateCurrentAccount = (account: AccountType) =>
    client
      .put(API_URL_ACCOUNT, {
        data: { full_name: account.full_name, photo_id: account.photo_id, phone_number: account.phone_number, language: account.language }
      })
      .then((rs: AxiosResponse<FastJsonObject<AccountType>>) => {
        // set_account_data(rs.data)
        return fetchAndStoreUser().then(() => fetchCurrentAccount().then(() => rs))
      })
  const triggerFetchData = useCallback(() => {
    return fetchCurrentAccount().then(() => Promise.resolve(undefined))
  }, [fetchCurrentAccount])
  useEffect(() => {
    regiterCallback(triggerFetchData)
    return () => unregisterCallBack(triggerFetchData)
  }, [triggerFetchData])
  return { fetchCurrentAccount, account_data, updateCurrentAccount, loadingData }
}
